<script setup lang="ts">
import VInlineButton from "@magnit/core/src/components/VInlineButton/VInlineButton.vue";
import VListItem from "@magnit/core/src/components/VListItem/VListItem.vue";
import VText from "@magnit/core/src/components/VText/VText.vue";
import VIcon from "@magnit/icons/src/components/VIcon/VIcon.vue";
import BackIcon from "@magnit/icons/src/assets/icons/arrow-left-corner-24.svg";
import ArrowIcon from "@magnit/icons/src/assets/icons/arrow-right-corner-small-24.svg";
import type { ICategory } from "~/store/categories";

const RESET_SEARCH_CATALOG_DELAY = 500;

interface IHeaderSearchCatalogProps {
  suggestsOpen: boolean;
}

const props = defineProps<IHeaderSearchCatalogProps>();

const emit = defineEmits<{
  close: [];
}>();

const router = useRouter();
const categoriesStore = useCategoriesStore();

const backPath = ref<(ICategory | null)[]>([]);
const currentCategory = ref<ICategory | null>(null);

const currentItems = computed(() => {
  return currentCategory.value
    ? currentCategory.value.children
    : categoriesStore.structure;
});

const onBackClick = () => {
  backPath.value.pop();
  currentCategory.value = backPath.value.pop() || null;
};

const onMainClick = () => {
  if (currentCategory.value) {
    emit("close");
    router.push(currentCategory.value.url);
  }
};

const resetCatalog = () => {
  backPath.value = [];
  currentCategory.value = null;
};

const onItemClick = (category: ICategory) => {
  if (category.children.length) {
    currentCategory.value = category;
    backPath.value.push(category);
  } else {
    emit("close");
    router.push(category.url);
  }
};

watch(
  () => props.suggestsOpen,
  () => {
    if (!props.suggestsOpen) {
      setTimeout(() => {
        resetCatalog();
      }, RESET_SEARCH_CATALOG_DELAY);
    }
  },
);
</script>

<template>
  <div ref="rootElement" class="header-search-catalog-step">
    <div class="header-search-catalog-step__content">
      <div v-if="currentCategory" class="header-search-catalog-caption">
        <VListItem
          class="header-search-catalog-item header-search-catalog-item_back"
          :divider="false"
          valign="center"
        >
          <VInlineButton
            icon-position="left"
            theme="secondary"
            @click="onBackClick"
          >
            <template #icon>
              <VIcon>
                <BackIcon />
              </VIcon>
            </template>

            Назад
          </VInlineButton>
        </VListItem>

        <VListItem
          class="header-search-catalog-item header-search-catalog-item_main"
          :divider="false"
          valign="center"
        >
          <VText font="title-medium">
            {{ currentCategory.name }}
          </VText>

          <template v-if="currentCategory.icon" #left>
            <AppImage :src="currentCategory.icon" :alt="currentCategory.name" />
          </template>
        </VListItem>

        <VListItem
          class="header-search-catalog-item"
          :divider="false"
          valign="center"
          @click="onMainClick"
        >
          Все товары этой категории
        </VListItem>
      </div>

      <div class="header-search-catalog-items">
        <VListItem
          v-for="item in currentItems"
          :key="item.key"
          :divider="false"
          class="header-search-catalog-item"
          valign="center"
          @click="onItemClick(item)"
        >
          <VText font="body-large-regular">
            {{ item.name }}
          </VText>

          <template v-if="item.icon" #left>
            <AppImage :src="item.icon" :alt="item.name" />
          </template>

          <template v-if="item.children.length" #right>
            <VIcon>
              <ArrowIcon />
            </VIcon>
          </template>
        </VListItem>
      </div>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.header-search-catalog-step {
  position: relative;
  overflow-y: auto;
  height: 100%;

  --_step-content-px: var(--pl-unit-x1);

  &__content {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding-left: var(--_step-content-px);
    padding-right: var(--_step-content-px);
    padding-bottom: var(--_step-content-px);
  }
}

.header-search-catalog-caption {
  position: relative;
  padding-top: var(--pl-unit-x4);

  &::after {
    content: "";
    position: absolute;
    left: var(--pl-unit-x2);
    right: var(--pl-unit-x2);
    bottom: 0;
    background: var(--pl-divider-primary);
    height: 1px;
  }
}

.header-search-catalog-item {
  padding-top: var(--pl-unit-x3);
  padding-bottom: var(--pl-unit-x3);

  .header-search-catalog-step & {
    border-radius: var(--pl-unit-x3);
    padding-left: var(--pl-unit-x2);
    padding-right: var(--pl-unit-x2);

    &:not(&_back, &_main) {
      cursor: pointer;
      background-color: var(--pl-surface-primary-default);

      &:hover {
        background-color: var(--pl-surface-primary-hover);
      }

      &:active {
        background-color: var(--pl-surface-primary-pressed);
      }
    }
  }

  :deep(img) {
    margin-top: var(--pl-unit-x1);
    width: 32px;
    height: 32px;
    border-radius: var(--pl-unit-x2);
    overflow: hidden;
  }

  &_back {
    .header-search-catalog-caption & {
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  &_main {
    .header-search-catalog-caption & {
      height: 64px;
    }
  }
}

@media (--pl-viewport-from-s) {
  .header-search-catalog-step {
    --_step-content-px: var(--pl-unit-x3);
  }
}

@media (--pl-viewport-from-m) {
  .header-search-catalog-step {
    --_step-content-px: var(--pl-unit-x6);
  }
}

@media (--pl-viewport-from-l) {
  .header-search-catalog-step {
    --_step-content-px: var(--pl-unit-x3);
  }
}
</style>
