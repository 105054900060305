import type { IShopSelectShop } from "@magnit/unit-shop-select/src/shop-select.types";
import type {
  IStoreSearchBoundingBox,
  IStoreSearchStore,
} from "~/typings/api/storeSearch";

export default () => {
  const { fetchAddressSearch, fetchGeoSearch } = storeSearchApi();
  const { selectedStore, currentStore } = storeToRefs(useStoresStore());

  const mapBounds = ref<IStoreSearchBoundingBox | null>(null);
  const shopSearchQuery = ref("");
  const listLoadingToken = ref<number | null>(null);
  const mapLoading = ref(true);
  const targetShopRef = ref<IStoreSearchStore | undefined>();
  const storesListRef = ref<IStoreSearchStore[]>([]);
  const mapCenterRef = ref<number[] | null>(
    currentStore.value
      ? [currentStore.value.latitude, currentStore.value.longitude]
      : null,
  );

  let abortController: AbortController | null = null;
  const storeTypes = STORE_ALLOWED_SHOP_TYPES_NUMERIC;

  const mapCenter = computed(() => mapCenterRef.value ?? []);
  const listLoading = computed(() => listLoadingToken.value !== null);
  const selectedShop = computed(
    () => selectedStore.value ?? currentStore.value,
  ) as ComputedRef<IShopSelectShop | undefined>;
  const fetchData = async (
    func: (signal: AbortSignal) => Promise<IStoreSearchStore[]>,
  ) => {
    const token = Date.now();
    try {
      if (abortController !== null)
        abortController.abort("Отменено новым запросом");
      listLoadingToken.value = token;
      abortController = new AbortController();
      const result = await func(abortController.signal);
      storesListRef.value = result;
    } catch (err) {
      logError("При попытке запроса магазинов, произошла ошибка", { err });
    } finally {
      if (listLoadingToken.value === token) listLoadingToken.value = null;
      if (mapLoading.value) mapLoading.value = false;
      abortController = null;
    }
  };
  const handleMapBoundsChange = (bb: IStoreSearchBoundingBox | null) => {
    if (!bb || shopSearchQuery.value.length >= STORE_SEARCH_STRING_MIN_LENGTH)
      return;
    return fetchData(async (signal: AbortSignal) => {
      const body = {
        aggs: false,
        geoBoundingBox: bb,
        limit: API_GEO_SEARCH_LIMIT,
        storeTypes,
      };
      const data = await fetchGeoSearch(body);
      signal.throwIfAborted();
      mapBounds.value = bb;
      return data?.stores ?? [];
    });
  };
  const handleSearchQueryChange = (val?: string) => {
    if (val !== undefined) shopSearchQuery.value = val;
    const query = shopSearchQuery.value;
    if (!query.length) {
      if (mapBounds.value !== null)
        return handleMapBoundsChange(mapBounds.value);
    }
    if (query.length < STORE_SEARCH_STRING_MIN_LENGTH) return;
    return fetchData(async (signal: AbortSignal) => {
      const body = {
        query,
        storeTypes,
        limit: API_ADDRESS_SEARCH_LIMIT,
      };
      const data = await fetchAddressSearch(body);
      signal.throwIfAborted();
      return data?.stores ?? [];
    });
  };

  watch(selectedStore, (next) => {
    if (mapCenterRef.value !== null || !next) return;
    mapCenterRef.value = [next.latitude, next.longitude];
  });

  return {
    mapBounds,
    targetShopRef,
    shopSearchQuery,
    listLoading,
    mapLoading,
    storesListRef,
    selectedShop,
    shopsFilterOptions: [],

    selectedStore,
    mapCenter,

    handleMapBoundsChange,
    handleSearchQueryChange,
  };
};
