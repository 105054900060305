<script setup lang="ts">
import VModal from "@magnit/core/src/components/VModal/VModal.vue";
import VButton from "@magnit/core/src/components/VButton/VButton.vue";
import VText from "@magnit/core/src/components/VText/VText.vue";
import EighteenPlusIcon from "~/assets/svg/base-illustration-112-eighteen-plus.svg";

defineProps<{
  open: boolean;
}>();
const emit = defineEmits<{
  approve: [v: boolean];
}>();
const onApprove = () => emit("approve", true);
const onReject = () => emit("approve", false);
</script>

<template>
  <VModal
    id="unit-modal-adult"
    wrapper-class="unit-modal-adult"
    :closable="false"
    :open="open"
    :type="{ xs: 'bottom-sheet', m: 'modal' }"
    :active="open"
  >
    <div class="unit-modal-adult__content">
      <EighteenPlusIcon class="unit-modal-adult__icon" />
      <VText tag="div" font="title-large">Вам есть 18 лет?</VText>
      <VText tag="div" font="body-large-regular">
        На этой странице есть товары, которые можно смотреть только с 18 лет
      </VText>
    </div>
    <template #footer>
      <div class="unit-modal-adult__buttons">
        <VButton full-width @click="onApprove()">Да, есть</VButton>
        <VButton theme="secondary" full-width @click="onReject()">
          Нет, перейти на главную
        </VButton>
      </div>
    </template>
  </VModal>
</template>

<style scoped lang="postcss">
.unit-modal-adult {
  &__buttons {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: var(--pl-unit-x2);
    margin-bottom: var(--pl-unit-x4);
  }

  &__icon {
    margin-bottom: var(--pl-unit-x1);
  }

  .pl-modal_type-bottom-sheet {
    .unit-modal-adult__buttons {
      gap: var(--pl-unit-x4);
    }
  }
}
</style>
