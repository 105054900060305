<script setup lang="ts">
import { computed, ref, watch } from "vue";
import VModal from "@magnit/core/src/components/VModal/VModal.vue";
import VHidden from "@magnit/core/src/components/VHidden/VHidden.vue";
import VOTPStep from "~/containers/Modals/VLoginModal/VOTPStep.vue";
import VPhoneStep from "~/containers/Modals/VLoginModal/VPhoneStep.vue";
import BackwardIcon from "~/assets/svg/icons/backward-l.svg";
import { useOTPStore } from "~/store/otp";
import { useAuthStore } from "~/store/auth";
import { MODALS, useModalsStore } from "~/store/modals";

const emit = defineEmits<{
  close: [];
}>();

const authStore = useAuthStore();
const otpStore = useOTPStore();
const modalsStore = useModalsStore();
const { send } = useAnalytics();

const step = ref<"phone" | "otp">("phone");

const isActive = computed(() => {
  return modalsStore.getActive() === MODALS.Login;
});

const isOpen = computed(() => {
  return modalsStore.isOpen(MODALS.Login);
});

const title = computed(() => {
  return step.value === "phone" ? "Вход и регистрация" : "Введите код из СМС";
});

const subtitle = computed(() => {
  return step.value === "phone"
    ? "Нужен только номер телефона"
    : `Отправлен на номер ${otpStore.state.phone.replace(/ /g, "\xA0")}`;
});

const onBackward = () => {
  step.value = "phone";
  otpStore.status.code = "initial";
  otpStore.status.auth = "initial";
  send("Login:OTP:Back");
};

const onClose = () => {
  step.value = "phone";
  otpStore.state.phone = "";
  otpStore.state.code = "";
  otpStore.status.code = "initial";
  otpStore.status.auth = "initial";

  modalsStore.close(MODALS.Login);
  emit("close");
  send("Login:Phone:Close");
};

watch(
  () => [otpStore.status.code, otpStore.status.auth],
  ([statusCode, statusAuth]) => {
    if (statusCode === "success") {
      step.value = "otp";
    }

    if (statusAuth === "success") {
      onClose();
    }
  },
);

watch(
  () => authStore.status.login,
  (authStatus) => {
    if (authStatus === "success") {
      onClose();
    }
  },
);
</script>

<template>
  <VModal
    :id="MODALS.Login"
    :active="isActive"
    :open="isOpen"
    :title="title"
    :subtitle="subtitle"
    :type="{ xs: 'bottom-sheet', m: 'modal' }"
    @header-left="onBackward"
    @close="onClose"
  >
    <template #headerLeft>
      <VHidden :hide="step === 'phone'">
        <BackwardIcon />
      </VHidden>
    </template>

    <VPhoneStep v-if="step === 'phone'" />

    <VOTPStep v-else />
  </VModal>
</template>
