import { computed, onMounted, onUnmounted, ref } from "vue";

export const formatCountdownToMMSS = (countdown: number[] | null) => {
  if (!countdown) {
    return "";
  }
  const [dd, hh, mm, ss] = countdown;
  const minutes = mm + dd * hh * 60;
  return `${String(minutes).padStart(2, "0")}:${String(ss).padStart(2, "0")}`;
};

/**
 * @example
 * const countdown = useCountdown("2020-07-20T13:10:39.235Z");
 * const countdown = useCountdown(1651080332054);
 * const onClick = () => overlay.activate();
 */
const useCountdown = (targetTime?: string | number) => {
  const now = ref(Date.now());
  const target = ref(targetTime ? new Date(targetTime) : null);
  const interval = ref(0);
  const status = ref<"initial" | "ticking" | "expired">("initial");

  const countdown = computed(() => {
    if (!target.value) {
      return null;
    }

    let diff = target.value.getTime() - now.value;
    diff = diff > 0 ? diff : 0;

    if (diff < 1000) {
      stop();

      status.value = "expired";
      return [0, 0, 0, 0];
    }

    const dd = diff > 0 ? Math.floor(diff / 1000 / 60 / 60 / 24) : 0;
    diff -= dd * 1000 * 60 * 60 * 24;
    const hh = diff > 0 ? Math.floor(diff / 1000 / 60 / 60) : 0;
    diff -= hh * 1000 * 60 * 60;
    const mm = diff > 0 ? Math.floor(diff / 1000 / 60) : 0;
    diff -= mm * 1000 * 60;
    const ss = diff > 0 ? Math.floor(diff / 1000) : 0;

    if (dd + hh + mm + ss === 0) {
      status.value = "expired";
    }

    return [dd, hh, mm, ss];
  }, {});

  const start = () => {
    stop();
    interval.value = window.setInterval(() => {
      status.value = "ticking";
      now.value = Date.now();
    }, 1000);
  };

  const stop = () => {
    if (interval.value) {
      status.value = "initial";
      window.clearInterval(interval.value);
    }
  };

  onMounted(() => {
    start();
  });

  onUnmounted(() => {
    stop();
  });

  return {
    update: (targetTime: string | number) => {
      target.value = new Date(targetTime);
      start();
    },
    value: countdown,
    status,
  };
};

export default useCountdown;
