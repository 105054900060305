<script setup lang="ts">
import VText from "@magnit/core/src/components/VText/VText.vue";
import VButton from "@magnit/core/src/components/VButton/VButton.vue";
import CosmeticIcon from "@magnit/icons/src/assets/icons/logo-cosmetic-16-colored-bg-invert.svg";
import { SHOPS_NAMES_MAP } from "@magnit/unit-shop-select/src/constants";
import { ShopSelectShopType } from "@magnit/unit-shop-select/src/enums";
import type { IStoreSearchStore } from "~/typings/api/storeSearch";

const router = useRouter();
const storesStore = useStoresStore();
const { success } = useToasts();
const { previouslySelectedStore, diffStore, currentStoreAddress } =
  storeToRefs(storesStore);

const diffStoreAddress = computed(() =>
  diffStore.value ? diffStore.value.address : "",
);
const diffStoreType = computed(() =>
  diffStore.value ? diffStore.value.storeType : ShopSelectShopType.AtHouse,
);

const storeTypeLabel = computed(
  () => SHOPS_NAMES_MAP[diffStoreType.value as ShopSelectShopType],
);

const setSelectedStore = (
  store: IStoreSearchStore,
  displayNotification = false,
) => {
  try {
    storesStore.setSelectedStore(store, true);
  } catch (err) {
    logError("Ошибка при выставлении магазина в ShopLocation ", { err });
    storesStore.setDefaultStore();
  } finally {
    if (displayNotification) {
      success({
        text: `Теперь показываем каталог магазина по адресу ${currentStoreAddress.value}`,
      });
    }
  }
};
const handleChangeStore = () => setSelectedStore(diffStore.value!, true);
const handleCloseStoreChange = async () => {
  await router.push({ path: "/" });
  setSelectedStore(previouslySelectedStore.value!);
};
</script>

<template>
  <div class="app-location-store">
    <div class="app-location-store__text">
      <VText tag="div" font="title-large">
        Чтобы посмотреть предложение, измените магазин
      </VText>
      <VText tag="div" font="body-large-regular">
        Предложение действует в одном магазине:
      </VText>
    </div>
    <div class="app-location-store__inner">
      <CosmeticIcon class="app-location-store__inner--icon" />
      <div class="app-location-store__inner--info">
        <VText class="app-location__info--city" font="body-large-accent-low">
          {{ storeTypeLabel }}
        </VText>
        <VText
          class="app-location__info--address"
          font="body-small-regular-low"
          color="secondary"
        >
          {{ diffStoreAddress }}
        </VText>
      </div>
    </div>
    <div class="app-location-store__inner--actions">
      <VButton full-width @click="handleChangeStore"> Изменить </VButton>
      <VButton full-width theme="secondary" @click="handleCloseStoreChange">
        Нет, закрыть
      </VButton>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.app-location-store {
  &__text {
    display: flex;
    flex-direction: column;
    row-gap: var(--pl-unit-x2);
  }

  &__inner {
    display: flex;
    gap: var(--pl-unit-x3);
    align-items: start;
    width: 100%;
    margin: var(--pl-unit-x4) 0 var(--pl-unit-x8) 0;
    border: 1px solid var(--pl-border-secondary-disabled);
    border-radius: var(--pl-button-radius-l);
    padding: var(--pl-unit-x3) var(--pl-unit-x4);
    background-color: var(--pl-button-background-tertiary-default);

    &--icon {
      width: 16px;
      height: 20px;
    }

    &--info {
      display: flex;
      flex-direction: column;
      row-gap: var(--pl-unit-x1);
      max-width: 336px;
    }

    &--actions {
      display: flex;
      gap: var(--pl-unit-x4);
    }
  }
}
</style>
