<script setup lang="ts">
import { ref, watch, computed } from "vue";
import { vIntersectionObserver } from "@vueuse/components";
import VButton from "@magnit/core/src/components/VButton/VButton.vue";
import VInputCode from "@magnit/core/src/components/VInputCode/VInputCode.vue";
import VFieldControl from "~/components/VFieldControl.vue";
import YaCaptcha from "~/components/YaCaptcha.vue";
import useCountdown, {
  formatCountdownToMMSS,
} from "~/composables/useCountdown";
import { useOTPStore } from "~/store/otp";

const otpStore = useOTPStore();
const countdown = useCountdown(otpStore.state.expire);
const { send } = useAnalytics();

const yaCaptcha = ref<null | string>(null);
const yaCaptchaRef = ref<null | InstanceType<typeof YaCaptcha>>(null);
const inputCodeRef = ref<null | InstanceType<typeof VInputCode>>(null);

const code = ref("");

watch(code, (next) => {
  otpStore.checkCode(next);
  if (next.length === 5) {
    send("Login:OTP:Code:Click");
  }
});

const isSubmitDisabled = computed(
  () =>
    otpStore.status.auth === `pending` ||
    countdown.status.value === `ticking` ||
    !yaCaptcha.value,
);
const submitBtnText = computed(() =>
  countdown.status.value === `expired`
    ? "Отправить код повторно"
    : `Повторить запрос через ${formatCountdownToMMSS(countdown.value.value)}`,
);

const onYaCaptchaTokenChange = (token?: string) => {
  if (token) {
    yaCaptcha.value = token;
  }
};

const onSubmit = () => {
  otpStore.getCode(yaCaptcha.value);
  yaCaptcha.value = null;
  yaCaptchaRef.value?.reset();
  send("Login:OTP:Code:Repeat");
};

watch(
  () => otpStore.status.auth,
  (nextStatus) => {
    if (nextStatus === "error") {
      setTimeout(() => {
        inputCodeRef.value?.clear();
        inputCodeRef.value?.focus();
      }, 3000);
    }
  },
);

watch(
  () => otpStore.state.expire,
  (nextExpire) => {
    if (nextExpire) {
      countdown.update(nextExpire);
    }
  },
);

const onVisibility = ([{ isIntersecting }]: IntersectionObserverEntry[]) => {
  if (isIntersecting) {
    send("Login:OTP:View");
  }
};
</script>

<template>
  <div v-intersection-observer="onVisibility">
    <VFieldControl
      class="otp-step__control"
      :has-error="otpStore.status.auth === 'error'"
      error-text="Неверный код. Попробуйте ещё раз"
    >
      <div class="otp-step__input">
        <VInputCode
          ref="inputCodeRef"
          v-model="code"
          :has-error="otpStore.status.auth === 'error'"
          :success="otpStore.status.auth === 'success'"
          :disabled="otpStore.status.auth === `pending`"
        />
      </div>
    </VFieldControl>

    <VButton
      full-width
      :disabled="isSubmitDisabled"
      :loading="
        otpStore.status.auth === `pending` ||
        countdown.status.value === `initial`
      "
      @click="onSubmit"
      >{{ submitBtnText }}
    </VButton>

    <YaCaptcha
      v-show="
        otpStore.status.auth === `success` ||
        countdown.status.value === `expired`
      "
      ref="yaCaptchaRef"
      class="otp-step__captcha"
      @token-changed="onYaCaptchaTokenChange"
    />
  </div>
</template>

<style lang="postcss">
.otp-step__control {
  margin-bottom: 16px;
}

.otp-step__input {
  display: flex;
  justify-content: center;
}

.otp-step__captcha {
  margin: 16px 0 0;
}
</style>
