<template>
  <div class="header-search-skeleton">
    <div class="header-search-skeleton__item" />
    <div class="header-search-skeleton__item" />
    <div class="header-search-skeleton__item" />
    <div class="header-search-skeleton__item" />
    <div class="header-search-skeleton__item" />
  </div>
</template>

<style lang="postcss" scoped>
.header-search-skeleton {
  display: flex;
  flex-direction: column;
  gap: var(--pl-unit-x7);
  padding: var(--pl-unit-x4) var(--pl-unit-x5);

  &__item {
    height: 47px;
    border-radius: var(--pl-unit-x4);
    width: 100%;
    background: linear-gradient(
      270deg,
      var(--pl-surface-subdued-pressed),
      var(--pl-surface-muted-default),
      var(--pl-surface-subdued-pressed)
    );
    background-size: 600% 600%;
    animation: skeleton-animation 3s ease infinite;
  }
}

@keyframes skeleton-animation {
  0% {
    background-position: 0 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0 50%;
  }
}
</style>
