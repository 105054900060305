<script setup lang="ts">
import { onBeforeMount, onMounted, watch, watchEffect, ref } from "vue";
import { useScroll } from "@vueuse/core";
import VTopButton from "@magnit/core/src/components/VTopButton/VTopButton.vue";
import VCookiePolicyPopup from "@magnit/unit-cookie-policy/src/VCookiePolicyPopup.vue";
import VYaSlotErrorPopup from "@magnit/core/src/containers/VYaSlot/VYaSlotErrorPopup.vue";
import VLoginModal from "~/containers/Modals/VLoginModal/VLoginModal.vue";
import VFooter from "~/containers/VFooter/VFooter.vue";
import VModalAdult from "~/containers/VModalAdult.vue";
import { useModalsStore } from "~/store/modals";

const nuxt = useNuxtApp();
initLogger(nuxt.$logger);
const router = useRouter();
const route = useRoute();
const otpStore = useOTPStore();
const authStore = useAuthStore();
const userStore = useUserStore();
const categoriesStore = useCategoriesStore();
const storesStore = useStoresStore();
const { loadSlotApiInit } = useSlotsStore();
const { requestShopCode, diffShopCode, isDiffShopCodeQuery } =
  storeToRefs(storesStore);

const { kfpCid } = usePublicConfig();
await Promise.all(
  [
    storesStore.initializeSelectedStore(),
    !diffShopCode.value && categoriesStore.requestCategories(),
  ].filter(Boolean),
);
await Promise.all(
  [diffShopCode.value && categoriesStore.requestCategories()].filter(Boolean),
);

authStore.$onAction(({ name }) => {
  if (name === "logout") {
    otpStore.$reset();
    authStore.$reset();
    userStore.$reset();
    userStore.clearSavedUUID();
  }
});

onBeforeMount(() => {
  authStore.checkLogin();
});

watch(
  () => authStore.state.authorized,
  async (authorized) => {
    if (authorized) {
      await userStore.getProfile();
      await userStore.getPermissions();
    }
  },
);
watch(requestShopCode, () => categoriesStore.requestCategories());

const elWindow = ref<HTMLElement | Window | null>(null);
const windowScrollDirection = ref<"top" | "bottom">("bottom");
const windowScroll = useScroll(elWindow);

const displayMainSlot = computed(() => !isDiffShopCodeQuery.value);
const skeletonComponent = computed(() => {
  if (
    route.name === "promo-catalog-category-categorySlug" ||
    route.name === "catalog-category-slug"
  )
    return resolveComponent("LazyVSkeletonCatalogPage");
  return resolveComponent("LazyVSkeletonCommonPage");
});

onMounted(() => {
  elWindow.value = window;
  loadSlotApiInit();
  loadScript(`/scripts/${kfpCid.value}.das.obf.js`);
});

const onAdultApprove = (v: boolean) => {
  userStore.setAdultApprove(v);
  if (!v) router.push(Routes.Main);
};

watchEffect(() => {
  if (windowScroll.isScrolling) {
    if (windowScroll.directions.top) {
      windowScrollDirection.value = "top";
    } else if (windowScroll.directions.bottom) {
      windowScrollDirection.value = "bottom";
    }
  }
});

const slotsStore = useSlotsStore();
const cookiePopupOpen = ref(false);
const showYaSlotPopup = ref(false);
const modalsStore = useModalsStore();
watchEffect(() => {
  showYaSlotPopup.value =
    !slotsStore.slotPopupWasDisplayed &&
    !modalsStore.getActive() &&
    !cookiePopupOpen.value &&
    slotsStore.slotStatus === IYaSlotApiStatus.ERROR;
  if (showYaSlotPopup.value) {
    slotsStore.slotPopupWasDisplayed = true;
  }
});
</script>

<template>
  <Body
    class="body"
    :class="{
      body_scrolled:
        windowScroll && windowScroll.y.value > LAYOUT_BODY_SCROLLED_PADDING,
      'body_scrolled-top':
        windowScroll &&
        windowScroll.y.value > 0 &&
        windowScrollDirection === 'top',
      'body_scrolled-bottom':
        windowScroll &&
        windowScroll.y.value > 0 &&
        windowScrollDirection === 'bottom',
    }"
  />
  <LayoutDefaultHeader />
  <div v-show="displayMainSlot" class="page">
    <slot />
  </div>
  <component :is="skeletonComponent" v-if="!displayMainSlot" />
  <VFooter />

  <VLoginModal />
  <VCookiePolicyPopup @visible="(visible) => (cookiePopupOpen = visible)" />
  <VYaSlotErrorPopup :show="showYaSlotPopup" />
  <VTopButton />
  <VModalAdult
    :open="userStore.isAudultDialogOpened"
    @approve="onAdultApprove"
  />
</template>

<style lang="postcss">
.page {
  padding-top: 16px;
}
</style>
