import type { ISearchResultProduct } from "~/store/search";

export const getActiveYaSlotInfo = (parent: Element | HTMLElement) => {
  const items = parent.querySelectorAll(".yaslot-item");
  const currentItem = Array.from(items).find((i) => {
    const style = getComputedStyle(i);
    return style.display === "block";
  });

  const htmlElement = currentItem?.id
    ? document.getElementById(currentItem?.id)
    : null;
  const currentYaSlotItemId = htmlElement?.dataset.yaSlotId || currentItem?.id;
  const currentItemHref = currentItem?.querySelector("a")?.href;
  const currentItemLink = currentItemHref
    ? new URL(currentItemHref).searchParams.get("pf")
    : "";

  return {
    promo_id: currentYaSlotItemId,
    dest: currentItemLink?.toString(),
  };
};

export const collectSearchListPayload = (
  query: string,
  products: ISearchResultProduct[],
) => {
  return {
    query,
    list: products.map((item, idx) => {
      return {
        ...item,
        position: idx + 1,
      };
    }),
  };
};
