<script setup lang="ts">
const props = defineProps<{
  searchValue: string;
  openSuggest: boolean;
}>();

const emit = defineEmits<{
  "update:openSuggest": [value: boolean];
  "update:searchValue": [value: string];
}>();

const { send } = useAnalytics();
const searchStore = useSearchStore();
const { state, status } = storeToRefs(searchStore);

const suggetstsStatus = computed(() => status.value.suggests);
const items = computed(() =>
  [
    ...(state.value.promosSuggests ?? []),
    ...(state.value.goodsSuggests ?? []),
  ].slice(0, SUGGESTS_LIMIT),
);

const closeSuggests = () => {
  if (!props.openSuggest) {
    return;
  }
  emit("update:openSuggest", false);
};

const closeError = () => {
  closeSuggests();
  emit("update:searchValue", "");
  send("Search:Error:Button:Click", {
    query: props.searchValue,
    type_error: "noProduct",
  });
};

const checkSearchValue = () =>
  props.searchValue && props.searchValue.length >= 3;
const fetchSuggests = () =>
  checkSearchValue()
    ? searchStore.getSuggests(props.searchValue)
    : searchStore.clearSuggests();

const showEmptyGoods = computed(
  () =>
    props.openSuggest &&
    props.searchValue.length >= 3 &&
    ["error", "success"].includes(suggetstsStatus.value) &&
    !items.value.length,
);

watchDebounced(() => props.searchValue, fetchSuggests, { debounce: 300 });

watch(showEmptyGoods, (v) => {
  if (!v) return;
  send("Search:Error:View", {
    query: props.searchValue,
    type_error: "noProduct",
  });
});
</script>

<template>
  <AppSearchSuggestCatalog
    v-if="suggetstsStatus === 'initial'"
    :suggests-open="openSuggest"
    @close="closeSuggests"
  />
  <AppSearchSuggestSkeleton v-if="suggetstsStatus === 'pending'" />
  <AppSearchSuggestResults
    v-else-if="suggetstsStatus === 'success' && items.length"
    :items="items"
    :query="searchValue"
    @click="closeSuggests"
    @results="closeSuggests"
  />
  <div v-else-if="showEmptyGoods" class="search-suggest-error">
    <AppEmptyGoods @click:primary="closeError" @click:secondary="closeError" />
  </div>
</template>

<style scoped lang="postcss">
.search-suggest-error {
  padding: 0 20px 20px;
}
</style>
