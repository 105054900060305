<script setup lang="ts">
import VCatalogEmpty from "@magnit/unit-catalog/src/components/VCatalogEmpty.vue";

const emit = defineEmits<{
  "click:primary": [];
  "click:secondary": [];
}>();
</script>

<template>
  <VCatalogEmpty
    :url-primary-button="Routes.Catalog"
    :url-secondary-button="Routes.PromoCatalog"
    @click:primary="emit('click:primary')"
    @click:secondary="emit('click:secondary')"
  />
</template>
