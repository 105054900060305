<script lang="ts" setup>
import VButton from "@magnit/core/src/components/VButton/VButton.vue";
import type { ISearchResultProduct } from "~/store/search";
import { collectSearchListPayload } from "~/utils/analytics";

const props = defineProps<{
  query: string;
  items: ISearchResultProduct[];
}>();
const emit = defineEmits<{
  results: [];
  close: [];
}>();

const { send } = useAnalytics();
const queryState = useState(STATE_SEARCH_QUERY, () => props.query);

const showAll = () => {
  emit("results");
  send("Search:Button:More:Click");
};

onMounted(() => {
  send(
    "Search:Results:List:View",
    collectSearchListPayload(props.query, props.items),
  );
});

watch(
  () => props.query,
  (v) => (queryState.value = v),
);
</script>

<template>
  <div v-if="items.length" class="header-search-results">
    <AppSearchSuggestResultsItem
      v-for="result in items"
      :key="result.id"
      :product="result"
      @click="emit('close')"
    />
    <NuxtLink
      :to="encodeURI(`${Routes.Search}?term=${props.query}`)"
      class="header-search-results__button"
      @click="showAll"
    >
      <VButton v-if="items.length" full-width tag="span" theme="secondary">
        Показать все
      </VButton>
    </NuxtLink>
  </div>
</template>

<style lang="postcss" scoped>
.header-search-results {
  --_results-px: var(--pl-unit-x1);

  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  overflow: auto;
  padding: 0 var(--_results-px) var(--pl-unit-x5) var(--_results-px);

  &__button {
    margin: var(--pl-unit-x2) var(--pl-unit-x2) 0 var(--pl-unit-x2);
  }
}

@media (--pl-viewport-from-s) {
  .header-search-results {
    --_results-px: var(--pl-unit-x3);
  }
}

@media (--pl-viewport-from-m) {
  .header-search-results {
    --_results-px: var(--pl-unit-x6);
  }
}

@media (--pl-viewport-from-l) {
  .header-search-results {
    --_results-px: var(--pl-unit-x3);
  }
}
</style>
